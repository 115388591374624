import {parseCallToAction} from './call-to-action.serde'
import {PresentationSection, PresentationSectionItem} from '../types/presentation-section.types'
import {parseAsset2} from './asset.serde'
import {parseRichTextBlocks} from './rich-text.serde'
import {
  PresentationSectionFieldsFragment,
  PresentationSectionItemFieldsFragment,
} from '../../../../__generated__/datocms.types'

export function parsePresentationSection(section: PresentationSectionFieldsFragment): PresentationSection {
  return {
    __type: 'PresentationSection',
    plot: section.plot!,
    title: section.title!,
    items:
      section.items?.map(item => {
        return parsePresentationSectionItem(item!)
      }) || [],
  }
}

export function parsePresentationSectionItem(item: PresentationSectionItemFieldsFragment): PresentationSectionItem {
  return {
    title: item.title!,
    content: {
      json: item.content?.json!,
      blocks: parseRichTextBlocks(item.content?.blocks ?? []),
    },
    youtubeId: item.youtubeId,
    image: item.image ? parseAsset2(item.image) : undefined,
    primaryCallToAction: item.primaryCta ? parseCallToAction(item.primaryCta) : undefined,
    secondaryCallToAction: item.secondaryCta ? parseCallToAction(item.secondaryCta) : undefined,
  }
}
